import AuthService from "./authService";
import { getBaseURL } from "./Helpers/endpoints";

class ActorService {
  constructor() {
    this.authService = new AuthService();
    this.baseUrl = getBaseURL();
    this.token = this.authService.getToken();
  }

  async getActorByUUID(actorId) {
    console.log(this.token);
    const url = `${this.baseUrl}actor?id=${actorId}&key=${this.token}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await this.authService.fetch(url, options);
      if (response.success) {
        return response.data.data[0];
      } else {
        console.error("Error fetching actor details from the API", response);
        return null;
      }
    } catch (error) {
      console.error("Error fetching actor details from the API", error);
      return null;
    }
  }

  async getActors() {
    const url = `${this.baseUrl}actor?key=${this.token}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await this.authService.fetch(url, options);
      if (response.success) {
        return response.data;
      } else {
        console.error("Error fetching data from the API", response);
        return null;
      }
    } catch (error) {
      console.error("Error fetching data from the API", error);
      return null;
    }
  }
}

export default new ActorService();
