import React, { Fragment } from "react";
import cx from "classnames";

function ipAddressCheck(ipAddress) {
  console.log(ipAddress);

  if (!ipAddress) return false;

  var regEx =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  return ipAddress.match(regEx);
}

class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSearch: false,
      search: "",
    };
  }

  handleSearchInput = (e) => {
    this.setState({ search: e.target.value });
  };

  routeChange = (e) => {
    const ipLength = this.state.search.split(".");

    if (
      this.state.search !== "" &&
      ipLength.length === 4 &&
      ipAddressCheck(this.state.search)
    ) {
      return true;
    }

    e.preventDefault();
    this.setState({ activeSearch: !this.state.activeSearch, search: "" });
    return false;
  };

  handleEnter = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13 && this.routeChange(e)) {
      // Reemplazamos la ruta actual sin guardar en el historial
      window.location.replace("/ip/" + this.state.search);
    }
  };

  render() {
    return (
      <Fragment>
        <div
          className={cx("search-wrapper", {
            active: this.state.activeSearch,
          })}
        >
          <div className="input-holder">
            <input
              type="text"
              value={this.state.search}
              onChange={this.handleSearchInput}
              className="search-input"
              placeholder="Type IP Address"
              onKeyPress={this.handleEnter}
            />
            <button
              onClick={(e) => {
                if (this.routeChange(e)) {
                  window.location.replace("/ip/" + this.state.search);
                }
              }}
              className="search-icon"
            >
              <span />
            </button>
          </div>
          <button
            onClick={() => {
              this.setState({ activeSearch: !this.state.activeSearch });
            }}
            className="btn-close"
          />
        </div>
      </Fragment>
    );
  }
}

export default SearchBox;
