import React, { Fragment } from "react";

import {
  IoIosGrid,
  IoIosAnalytics,
} from "react-icons/io";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  DropdownItem,
} from "reactstrap";

import { AreaChart, Area, ResponsiveContainer } from "recharts";

import { faArrowLeft, faCog } from "@fortawesome/free-solid-svg-icons";

import CountUp from "react-countup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import bg4 from "../../../assets/utils/images/dropdown-header/abstract4.jpg";
import city2 from "../../../assets/utils/images/dropdown-header/city2.jpg";

import Flag from "react-flagkit";

const data = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Page C", uv: 2000, pv: 6800, amt: 2290 },
  { name: "Page D", uv: 4780, pv: 7908, amt: 2000 },
  { name: "Page E", uv: 2890, pv: 9800, amt: 2181 },
  { name: "Page F", uv: 1390, pv: 3800, amt: 1500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
];

class HeaderDots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  render() {
    return (
      <Fragment>
        <div className="header-dots">
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="link">
              <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                <div className="icon-wrapper-bg bg-focus" />
                <div className="language-icon">
                  <Flag className="me-3 opacity-8" country="US" size="40" />
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              <div className="dropdown-menu-header">
                <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                  <div className="menu-header-image opacity-05"
                    style={{
                      backgroundImage: "url(" + city2 + ")",
                    }}/>
                  <div className="menu-header-content text-center text-white">
                    <h6 className="menu-header-subtitle mt-0">
                      Choose Language
                    </h6>
                  </div>
                </div>
              </div>
              <DropdownItem header>Languages</DropdownItem>
              <DropdownItem>
                <Flag className="me-3 opacity-8" country="US" />
                English
              </DropdownItem>
              <DropdownItem>
                <Flag className="me-3 opacity-8" country="DE" />
                Deutch
              </DropdownItem>
              <DropdownItem>
                <Flag className="me-3 opacity-8" country="NL" />
                Nederlands
              </DropdownItem>
              <DropdownItem>
                <Flag className="me-3 opacity-8" country="FR" />
                Français
              </DropdownItem>
              <DropdownItem>
                <Flag className="me-3 opacity-8" country="ES" />
                Español
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Fragment>
    );
  }
}

export default HeaderDots;
