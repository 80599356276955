import { countryCodeToFlagEmoji } from "../../../Helpers/countryFlag";
import React from "react";

const AptCard = ({ actorDetails, hiddenBorders = false }) => {
  const cardStyle = {
    maxWidth: "64rem",
    margin: "auto",
    backgroundColor: "white",
    borderRadius: "0.5rem",
    overflow: "hidden",
    padding: "2rem",
  };

  if (!hiddenBorders) {
    cardStyle.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
  }

  return (
    <div style={cardStyle}>
      <div style={{ marginBottom: "2rem" }}>
        <h1
          style={{
            fontSize: "1.875rem",
            fontWeight: "bold",
            marginBottom: "0.5rem",
            color: "#1a202c",
          }}
        >
          {actorDetails.actor_name}
        </h1>
      </div>
      <div style={{ marginBottom: "2rem" }}>
        <h3>Location</h3>
        {actorDetails.actor_location && (
          <p style={{ marginBottom: "0.5rem" }}>
            {actorDetails.actor_location}{" "}
            {countryCodeToFlagEmoji(actorDetails.actor_country)}
          </p>
        )}
      </div>
      <div style={{ marginBottom: "2rem" }}>
        <h3>Description</h3>
        <p style={{ marginBottom: "1rem" }}>{actorDetails.actor_description}</p>
      </div>
      {actorDetails.other_names && (
        <div style={{ marginBottom: "2rem" }}>
          <h3>Other Names</h3>
          <div
            style={{ marginBottom: "1rem", display: "flex", flexWrap: "wrap" }}
          >
            {actorDetails.other_names.split(",").map((name, index) => (
              <div
                key={index}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#4299e1",
                  borderRadius: "9999px",
                  padding: "0.25rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "semibold",
                  color: "white",
                  marginRight: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {name.trim()}
              </div>
            ))}
          </div>
        </div>
      )}
      {actorDetails.actor_sponsor && (
        <div style={{ marginBottom: "2rem" }}>
          <h3>Sponsor</h3>
          <p>{actorDetails.actor_sponsor}</p>
        </div>
      )}
      {actorDetails.actor_first_seen && (
        <div style={{ marginBottom: "2rem" }}>
          <h3>First Seen</h3>
          <p>{actorDetails.actor_first_seen}</p>
        </div>
      )}
      {actorDetails.actor_targets && (
        <div style={{ marginBottom: "2rem" }}>
          <h3>Targets</h3>
          <p style={{ marginBottom: "1rem" }}>
            {actorDetails.actor_targets}
          </p>{" "}
          {/* TODO: Add column to DB */}
        </div>
      )}
      {actorDetails.actor_targets && (
        <div style={{ marginBottom: "2rem" }}>
          <h3>Targets</h3>
          <p style={{ marginBottom: "1rem" }}>
            {actorDetails.actor_targets}
          </p>{" "}
          {/* TODO: Add column to DB */}
        </div>
      )}
      {actorDetails.actor_targetcategories && (
        <div style={{ marginBottom: "2rem" }}>
          <h3>Target Categories</h3>
          <p style={{ marginBottom: "1rem" }}>
            {actorDetails.actor_targetcategories}
          </p>{" "}
          {/* TODO: Add column to DB */}
        </div>
      )}
      {actorDetails.actor_ext_links && (
        <div style={{ marginBottom: "2rem" }}>
          <h3>External links</h3>
          <p style={{ marginBottom: "1rem" }}>{actorDetails.actor_ext_links}</p>
        </div>
      )}
      {actorDetails.actor_sources && (
        <div style={{ marginBottom: "2rem" }}>
          <h3>Sources</h3>
          <p style={{ marginBottom: "1rem" }}>{actorDetails.actor_sources}</p>
        </div>
      )}
    </div>
  );
};

export default AptCard;
