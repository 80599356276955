import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import AppFooter from "../../Layout/AppFooter";
import AptCard from "../../Layout/AppMain/AptCard";
import {ClipLoader} from 'react-spinners';
import ActorService from "../../actorService";

const ActorDetail = () => {
    const {actorId} = useParams();
    const [actorDetails, setActorDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchActorDetails = async () => {
            const data = await ActorService.getActorByUUID(actorId);
            if (data) {
                setActorDetails(data);
                console.log(data);
            } else {
                console.error("Error fetching actor details");
            }
            setIsLoading(false);
        };

        fetchActorDetails().finally(() => setIsLoading(false));
    }, [actorId]);

    return (
        <Fragment>
            <AppHeader/>
            <div className="app-main">
                <AppSidebar/>
                <div className="app-main__outer">
                    <div className="app-main__inner" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        padding: '4rem 0'
                    }}>
                        {isLoading ? (
                            <ClipLoader color="#123abc" loading={isLoading} size={150}/>
                        ) : actorDetails ? (
                            <AptCard actorDetails={actorDetails}/>
                        ) : (
                            <p style={{fontSize: '1.5rem', color: 'grey'}}>
                                No results found for Actor UUID: {actorId}
                            </p>
                        )}
                    </div>
                    <AppFooter/>
                </div>
            </div>
        </Fragment>
    );
};

export default ActorDetail;
