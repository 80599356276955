import decode from "jwt-decode";
import { getBaseURL } from "./Helpers/endpoints";
import { getCSRFToken } from "./Helpers/utils";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default class AuthService {
  constructor() {
    this.baseUrl = getBaseURL();
  }

  login = async (username, password) => {
    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({
        username,
        password,
      }),
    };

    const response = await fetch(`${this.baseUrl}/auth/jwt/create/`, options);
    const result = await response.json();

    if (response.ok) {
      this.setRefreshToken(result.refresh);
      this.setToken(result.access);
      await this.setProfile();
    }

    return result;
  };

  signup = async (data) => {
    headers["X-CSRFToken"] = getCSRFToken();

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    };

    const response = await fetch(`${this.baseUrl}/auth/users/`, options);
    const result = await response.json();

    return result;
  };

  loggedIn = () => {
    const access_token = this.getToken();
    const refresh_token = this.getRefreshToken();

    if (!!access_token && !this.isTokenExpired(access_token)) {
      return true;
    }

    if (!!refresh_token && !this.isTokenExpired(refresh_token)) {
      return true;
    }

    return false;
  };

  renewAccessToken = async (refresh_token) => {
    const response = await fetch(`${this.baseUrl}/auth/jwt/refresh/`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        refresh: refresh_token,
      }),
    });

    const result = await response.json();

    if (result.access) {
      this.setToken(result.access);
    } else {
      this.logout();
    }
  };

  isTokenExpired(token) {
    const decoded = decode(token);
    return decoded.exp < Date.now() / 1000;
  }

  setRefreshToken(refresh_token) {
    localStorage.setItem("refresh_token", refresh_token);
  }

  getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  setToken(access_token) {
    localStorage.setItem("access_token", access_token);
  }

  getToken() {
    return localStorage.getItem("access_token");
  }

  logout() {
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("profile");
  }

  setProfile = async () => {
    const profile = await this.fetch(`${this.baseUrl}/auth/users/me/`, {
      method: "GET",
    });

    localStorage.setItem("profile", JSON.stringify(profile));
  };

  getProfile() {
    const profile = localStorage.getItem("profile");
    return JSON.parse(profile);
  }

  fetch = async (url, options) => {
    const access_token = this.getToken();
    const refresh_token = this.getRefreshToken();

    if (access_token && refresh_token) {
      if (this.isTokenExpired(access_token))
        await this.renewAccessToken(refresh_token);
      headers["Authorization"] = "JWT " + this.getToken();
    }

    const response = await fetch(url, {
      headers,
      ...options,
    });

    if (options.method !== "DELETE") {
      const result = await response.json();
      return result;
    }
  };
}
