// Backend rest api endpoints
import AuthService from "../authService";

const isLocal = process.env.REACT_APP_ENV === "local";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getBaseURL = () => {
  const domain = isLocal ? "" : "https://api.listed.black";
  return `${domain}/api-internal/v1`;
};

export const getCategories = async () => {
  const baseUrl = getBaseURL();
  const url = `${baseUrl}/categories/`;

  const response = await fetch(url, {
    method: "GET",
    headers,
  });

  const categories = await response.json();

  return categories.results;
};

export const getUsages = async () => {
  const baseUrl = getBaseURL();
  const url = `${baseUrl}/usages/`;

  const response = await fetch(url, {
    method: "GET",
    headers,
  });

  const usages = await response.json();

  return usages.results;
};

export const getTags = async () => {
  const Auth = new AuthService();

  const options = {
    method: "GET",
  };

  const response = await Auth.fetch(`${Auth.baseUrl}/tags/`, options);

  return response.results.map((item) => item.name);
};

export const getRegisteredHosts = async () => {
  const Auth = new AuthService();

  const options = {
    method: "GET",
  };

  const response = await Auth.fetch(`${Auth.baseUrl}/hosts/`, options);

  return response.results;
};

export const getWhitelists = async () => {
  const Auth = new AuthService();

  const options = {
    method: "GET",
  };

  const response = await Auth.fetch(`${Auth.baseUrl}/whitelists/`, options);

  return response.results;
};
