export const getCSRFToken = () => {
  const csrfCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("csrftoken="));
  if (csrfCookie) {
    return csrfCookie.split("=")[1];
  }
  return null;
};

export const getLocaleDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleString();
};

export const getDayAndMonth = (date) => {
  const newDate = new Date(date);
  const day = newDate.getUTCDate();
  const month = newDate.getUTCMonth() + 1;
  return `${day}/${month}`;
};

export const get_asn_rank_info = (rank) => {
  if (rank <= 0 || typeof rank !== "number") {
    return {};
  }
  if (rank > 0 && rank <= 0.0001) {
    return {
      classification: "Low-Risk",
      description:
        "Very few or no signs of malicious activity. The ASN is generally considered safe.",
      rank_range: "0.000000000000001 - 0.0001",
      color: "#00FF00", // Green
    };
  } else if (rank > 0.0001 && rank <= 0.001) {
    return {
      classification: "Moderate-Risk",
      description:
        "Some suspicious activity is present, but the ASN is still largely trusted. It may have isolated incidents.",
      rank_range: "0.0001 - 0.001",
      color: "#FFFF00", // Yellow
    };
  } else if (rank > 0.001 && rank <= 0.01) {
    return {
      classification: "High-Risk",
      description:
        "Clear signs of malicious activity, with multiple IPs or incidents tied to malware or botnet behavior.",
      rank_range: "0.001 - 0.01",
      color: "#FFA500", // Orange
    };
  } else if (rank > 0.01 && rank <= 0.1) {
    return {
      classification: "Very High-Risk",
      description:
        "Significant evidence of malicious activities, including multiple sources corroborating the ASN's involvement in malicious operations.",
      rank_range: "0.01 - 0.1",
      color: "#FF4500", // Red-Orange
    };
  } else if (rank > 0.1) {
    return {
      classification: "Critical Risk",
      description:
        "The ASN is highly involved in malicious activities across multiple datasets. It may host botnets, phishing operations, or large-scale malware attacks.",
      rank_range: "0.1 - 1.0 or higher",
      color: "#FF0000", // Red
    };
  } else {
    return {};
  }
};

export const generateSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "");
};
